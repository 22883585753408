* {
  margin: 0;
  padding: 0;
}

$body-text: #fff;
$body-bg: #000;
$site-font: "Space Mono", monospace;

body {
  color: $body-text;
  font-family: $site-font;
  font-size: 0.9rem;
  line-height: 1.6;
  background: $body-bg;
  overflow-x: hidden;
  @media (min-width: 768px) {
    overflow: hidden;
  }
}

a {
  color: $body-text;
  text-decoration: none;
}

button {
  background: transparent;
  color: $body-text;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-size: 1.3rem;
  font-family: "Space Mono", monospace;
}

img {
  max-width: 100%;
  height: auto;
}

.webgl {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  outline: none;
}

.logo {
  position: relative;
  padding: 30px;
  z-index: 3;
  @media (min-width: 768px) {
    position: fixed;
    padding: 0;
    top: 30px;
    left: 30px;
  }
}

.burger {
  position: relative;
  float: right;
  margin-top: -52px;
  padding: 0 30px 0 0;
  z-index: 3;
  list-style: none;
  @media (min-width: 768px) {
    float: none;
    margin-top: 0;
    padding: 0;
    position: fixed;
    top: 30px;
    right: 30px;
  }
}

.footer-contact {
  position: relative;
  float: left;
  padding: 30px;
  z-index: 3;
  @media (min-width: 768px) {
    float: none;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}

.footer-copy {
  position: static;
  padding: 30px;
  float: right;
  z-index: 3;
  @media (min-width: 768px) {
    float: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

.trasn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fe0b8f;
  z-index: 4;
}

.content {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @media (min-width: 600px) {
    height: 80vh;
  }
  @media (min-width: 768px) {
    height: 100vh;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
  @media (min-width: 1400px) {
    width: 1170px;
  }
}

.port-title {
  width: 100%;
  text-align: center;
  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1700px) {
    width: 90%;
  }
  @media (min-width: 2200px) {
    width: 100%;
  }
}

.port-img {
  width: 100%;
  text-align: center;
  @media (min-width: 768px) {
    width: 65%;
  }
  @media (min-width: 1700px) {
    width: 80%;
  }
  @media (min-width: 2200px) {
    width: 90%;
  }
}

.port-title h1 {
  font-weight: 800;
  //font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  margin: 0 0 1rem;
  color: $body-text;
  -webkit-text-fill-color: $body-bg;
  //-webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $body-text;
  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media (min-width: 992px) {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin: 0 0 0.5rem;
  }
}

.port-img p {
  font-size: 1rem;
  font-weight: 200;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.info-content {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    justify-content: center;
    width: 90%;
    height: 100vh;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 2000px) {
    width: 90%;
  }
}

.info-content h1 {
  font-weight: 800;
  //font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  margin: 0 0 1rem;
  color: $body-text;
  //-webkit-text-fill-color: $body-bg;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $body-text;
  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media (min-width: 992px) {
    font-size: 3.5vw;
    line-height: 3.5vw;
    margin: 0 0 1.3rem;
  }
}

.info-content h2 {
  font-size: 1.3rem;
  margin: 0 0 1.5rem;
  font-weight: 400;
  @media (min-width: 1200px) {
    font-size: 1.7vw;
    margin: 0 0 1.5rem;
  }
}

.info-list {
  list-style: none;
  max-width: auto;
  font-size: 1rem;
  @media (min-width: 768px) {
    max-width: 33rem;
  }
  @media (min-width: 1400px) {
    max-width: 37rem;
  }
}

.info-list li {
  padding: 0 0 1.5rem;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fe0b8f; // FUCKING PINK!
  @media (min-width: 768px) {
    padding-left: 0;
    justify-content: center;
    align-items: center;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swip-links {
  font-size: 0.8rem;
  @media (min-width: 768px) {
    font-size: 0.85em;
  }
}

.swip-links a {
  text-decoration: none;
}

//SWIPER OVERRIDES BELOW:

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ffffff !important;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical
  > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  bottom: 0 !important;
  top: auto !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  &:after {
    content: "prev";
  }
  left: 30px !important;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  &:after {
    content: "next";
  }
  right: 30px !important;
  left: auto;
}

.swiper-wrapper {
  height: auto;
  @media (min-width: 768px) {
    height: 100vh !important;
  }
}

/// SWIPER END

/// CURSOR

.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 150ms ease;
  transition-property: background-color, opacity, transform, mix-blend-mode;
  z-index: 9999;
  mix-blend-mode: difference;
}

.cursor--hidden {
  opacity: 0;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(2); // DEFAULT
  //background-color: #fefefe; // DEFAULT
  border: 2px solid #fe0b8f; // FUCKING PINK DUDE!
  background-color: #fe0b8f; // FUCKING PINK DUDE!
  border-radius: 64% 36% 47% 53% / 49% 66% 34% 51%; // Let's make the hover a weird blob
}

.cursor--link-hovered-swip {
  transform: translate(-50%, -50%) scale(2);
  background-color: none;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}

/// CURSOR END
